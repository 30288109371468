import React, { useContext } from 'react'
import { navigate } from 'gatsby-link'
import { SubmitHandler, useForm } from 'react-hook-form'

// Components
import TextField from '../../atoms/TextField'
import DilanButton from '../../atoms/DilanButton'

//Utils
import { getFont } from '../../../utils/utils'

// Context
import UserContext from '../../../context/User/User.context'

// Services
import { login } from '../../../services/auth'

// Constants
import { USER_TOKEN } from '../../../config/constants/localStorage'
import { LOGIN_REDIRECT } from '../../../config/constants/sessionStorage'

// Styles
import {
  formStyle,
  titleStyle,
  forgotStyle,
  subtitleStyle,
  actionButtons,
} from './styles.module.scss'

interface Props {
  localization: {
    welcome: string
    login_cta: string
    username: string
    password: string
    forgot_password: string
    login: string
    register: string
    password_format: string
    username_format: string
    required: string
    invalid_pass: string
    unexpected_error: string
  }
  setForm: (state: 'Login' | 'Register' | 'Forgot') => void
}

interface FormData {
  username: string
  password: string
}

const LoginForm: React.FC<Props> = ({ localization, setForm }) => {
  const { updateUser } = useContext(UserContext)

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({ mode: 'onChange' })

  const commonValidationObj = { required: localization.required }

  const passValidationObj = {
    ...commonValidationObj,
    pattern: {
      value: /^[A-Za-z0-9_#$%.,@-]*$/,
      message: localization.password_format,
    },
  }

  const userValidationObj = {
    ...commonValidationObj,
    pattern: {
      value: /^[A-Za-z0-9_-]*$/,
      message: localization.username_format,
    },
  }

  const handleForm: SubmitHandler<FormData> = async (data) => {
    try {
      const user = await login(data)
      localStorage.setItem(USER_TOKEN, user.token)
      const contextUser = {
        ...user.user,
        lastChapter: String(user.user.lastChapter),
        tier: user.user.tier ? String(user.user.tier) : undefined,
      }
      updateUser(contextUser)
      navigate(sessionStorage.getItem(LOGIN_REDIRECT) || '/')
    } catch (error) {
      if (error.response.data.message === 'Invalid password') {
        setError('password', { message: localization.invalid_pass })
      } else {
        setError('password', { message: localization.unexpected_error })
      }
    }
  }

  return (
    <>
      <h2 className={`${titleStyle} ${getFont('font-lato')}`}>
        {localization.welcome}
      </h2>
      <h3 className={`${subtitleStyle} ${getFont('font-lato')}`}>
        {localization.login_cta}
      </h3>
      <form onSubmit={handleSubmit(handleForm)} className={formStyle}>
        <TextField
          type="text"
          label={localization.username}
          error={errors.username?.message}
          autocomplete="username"
          register={register('username', userValidationObj)}
        />
        <div style={{ margin: '8px' }} />
        <TextField
          type="password"
          label={localization.password}
          error={errors.password?.message}
          autocomplete="current-password"
          register={register('password', passValidationObj)}
        />
        <div style={{ margin: '8px' }} />
        <div
          className={`${forgotStyle} ${getFont('font-lato')}`}
          onClick={() => {
            setForm('Forgot')
          }}
        >
          {localization.forgot_password}
        </div>
        <div style={{ margin: '16px' }} />
        <DilanButton
          type="secondary"
          className={`${actionButtons} ${getFont('font-patua')}`}
          handleClick={() => {}}
        >
          {localization.login}
        </DilanButton>
      </form>
      <div style={{ margin: '8px' }} />
      <DilanButton
        type="simple"
        className={`${actionButtons} ${getFont('font-patua')}`}
        handleClick={() => {
          setForm('Register')
        }}
      >
        {localization.register}
      </DilanButton>
    </>
  )
}

export default LoginForm
