import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

// Components
import TextField from '../../atoms/TextField'
import DilanButton from '../../atoms/DilanButton'

//Utils
import { getFont } from '../../../utils/utils'

// Styles
import {
  formStyle,
  titleStyle,
  subtitleStyle,
  actionButtons,
} from './styles.module.scss'

// Services
import {
  availableEmail,
  availableUsername,
  registerUser,
} from '../../../services/auth'

interface Props {
  localization: {
    welcome: string
    register_cta: string
    username: string
    password: string
    email: string
    passwordConfirm: string
    create: string
    register_legal: string
    terms: string
    privacy: string
    required: string
    password_format: string
    username_format: string
    confirm_invalid: string
    email_invalid: string
    min_username: string
    max_username: string
    unavailable_username: string
    unavailable_email: string
    min_password: string
  }
  setForm: (state: 'Login' | 'Register' | 'Forgot') => void
}

interface FormData {
  username: string
  email: string
  cellphone: string
  password: string
  confirmPassword: string
}

const RegisterForm: React.FC<Props> = ({ localization, setForm }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({ mode: 'onChange' })

  const checkUsername = async (value: string) => {
    const isAvailable = await availableUsername(value)
    if (isAvailable) {
      return true
    }
    return false
  }

  const checkEmail = async (value: string) => {
    const isAvailable = await availableEmail(value)
    if (isAvailable) {
      return true
    }
    return false
  }

  const commonValidationObj = { required: localization.required }

  const userValidationObj = {
    ...commonValidationObj,
    pattern: {
      value: /^[A-Za-z0-9_-]*$/,
      message: localization.username_format,
    },
    minLength: {
      value: 3,
      message: localization.min_username,
    },
    maxLength: {
      value: 50,
      message: localization.max_username,
    },
    validate: {
      available: async (v) =>
        (await checkUsername(v)) || localization.unavailable_username,
    },
  }

  const emailValidationObj = {
    ...commonValidationObj,
    pattern: {
      value: /^[A-Za-z0-9\.]*@[A-Za-z]*\.[A-Za-z\.]*$/,
      message: localization.email_invalid,
    },
    validate: {
      available: async (v) =>
        (await checkEmail(v)) || localization.unavailable_email,
    },
  }

  const passValidationObj = {
    ...commonValidationObj,
    pattern: {
      value: /^[A-Za-z0-9_#$%.,@-]*$/,
      message: localization.password_format,
    },
    minLength: {
      value: 6,
      message: localization.min_password,
    },
  }

  const confirmValidationObj = {
    ...commonValidationObj,
    validate: {
      confirm: (v) =>
        v === getValues('password') || localization.confirm_invalid,
    },
  }

  const handleForm: SubmitHandler<FormData> = async (data) => {
    const token = executeRecaptcha ? await executeRecaptcha('New_Comment') : ''
    await registerUser({ ...data, token })
    setForm('Login')
  }

  return (
    <>
      <h2 className={`${titleStyle} ${getFont('font-lato')}`}>
        {localization.welcome}
      </h2>
      <h3 className={`${subtitleStyle} ${getFont('font-lato')}`}>
        {localization.register_cta}
      </h3>
      <form onSubmit={handleSubmit(handleForm)} className={formStyle}>
        <input className="hidden" {...register('cellphone')} />
        <TextField
          type="text"
          label={localization.username}
          error={errors.username?.message}
          autocomplete="username"
          register={register('username', userValidationObj)}
        />
        <div style={{ margin: '8px' }} />
        <TextField
          type="text"
          label={localization.email}
          error={errors.email?.message}
          autocomplete="email"
          register={register('email', emailValidationObj)}
        />
        <div style={{ margin: '8px' }} />
        <TextField
          type="password"
          label={localization.password}
          error={errors.password?.message}
          autocomplete="new-password"
          register={register('password', passValidationObj)}
        />
        <div style={{ margin: '8px' }} />
        <TextField
          type="password"
          label={localization.passwordConfirm}
          error={errors.confirmPassword?.message}
          autocomplete="new-password"
          register={register('confirmPassword', confirmValidationObj)}
        />
        <div style={{ margin: '16px' }} />
        <DilanButton
          type="secondary"
          className={`${actionButtons} ${getFont('font-patua')}`}
          handleClick={() => {}}
        >
          {localization.create}
        </DilanButton>
      </form>
    </>
  )
}

export default RegisterForm
