import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'

// Components
import TextField from '../../atoms/TextField'
import DilanButton from '../../atoms/DilanButton'

//Utils
import { getFont } from '../../../utils/utils'

// Services
import { forgotPassword } from '../../../services/auth'

// Constants
import { LOCALIZATION } from '../../../config/constants/localStorage'

// Styles
import {
  formStyle,
  titleStyle,
  subtitleStyle,
  actionButtons,
  forgotModal,
  forgotModalIcon,
  forgotModalOverlay,
} from './styles.module.scss'

interface Props {
  localization: {
    welcome: string
    forgot_cta: string
    email: string
    send: string
    forgot_password: string
    forgot_prompt: string
    email_invalid: string
    required: string
    unexpected_error: string
  }
}

interface FormData {
  email: string
}

const ForgotForm: React.FC<Props> = ({ localization }) => {
  const {
    register,
    setError,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({ mode: 'onChange' })

  const [modal, setModal] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const commonValidationObj = { required: localization.required }

  const emailValidationObj = {
    ...commonValidationObj,
    pattern: {
      value: /^[A-Za-z0-9\.]*@[A-Za-z]*\.[A-Za-z\.]*$/,
      message: localization.email_invalid,
    },
  }

  const handleForm: SubmitHandler<FormData> = async (data) => {
    setDisabled(true)
    try {
      await forgotPassword(data.email, localStorage.getItem(LOCALIZATION) as string)
      setModal(true)
      setValue('email', '')
    } catch (error) {
      setError('email', { message: localization.unexpected_error })
    }
    setDisabled(false)
  }

  return (
    <>
      {modal && (
        <div
          className={forgotModalOverlay}
          onClick={() => {
            setModal(false)
          }}
        >
          <div className={forgotModal}>
            <div className={getFont('font-lato')}>
              {localization.forgot_prompt}
            </div>
            <FontAwesomeIcon className={forgotModalIcon} icon={faCheckCircle} />
            <DilanButton
              className={getFont('font-lato')}
              type={'primary'}
              handleClick={() => {
                setModal(false)
              }}
            >
              OK
            </DilanButton>
          </div>
        </div>
      )}
      <h2 className={`${titleStyle} ${getFont('font-lato')}`}>
        {localization.welcome}
      </h2>
      <h3 className={`${subtitleStyle} ${getFont('font-lato')}`}>
        {localization.forgot_cta}
      </h3>
      <form onSubmit={handleSubmit(handleForm)} className={formStyle}>
        <TextField
          type="text"
          label={localization.email}
          error={errors.email?.message}
          register={register('email', emailValidationObj)}
        />
        <div style={{ margin: '16px' }} />
        <DilanButton
          type="secondary"
          className={`${actionButtons} ${getFont('font-patua')}`}
          handleClick={() => {}}
          disabled={disabled}
        >
          {localization.send}
        </DilanButton>
      </form>
    </>
  )
}

export default ForgotForm
